.me-image {
  width: 17rem;
  border-radius: 25px;
}

.about-container {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-card {
  height: 19rem !important;
  width: 25% !important;
  padding: 20px !important;
}
