h1, .empty-cart {
    text-align: center;
  }
  
  .cart-item {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 2rem;
    padding: 1rem;
  
    .item-image {
      img {
        width: 50px;
      }
    }
  
    .name-price {
      h4 {
        margin: 0;
      }
      p {
        font-size: 1rem;
        font-weight: normal;
      }
    }
  
    .btns-container {
      button {
        border: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 10px;
        margin-right: 5px;
      }
  
      .btn-increase {
        color: #fff;
        background-color: #1a1a1a;
      }
  
      .btn-decrease, .btn-trash {
        color: #fff;
        background-color: #d9534f;
      }
    }
    
    .total-container {
      margin: 2rem;
      text-align: center;
    }
  }
  
  @media(min-width: 1024px) {
    .cart-page {
      display: flex;
      justify-content: center;
  
      .total-container {
        text-align: center;
      }
    }
    .cart-item {
      display: flex;
      justify-content: space-between;
      div {
        margin: 1rem;
      }
      .name-price {
        width: 200px;
      }
    }
  }

  .product-list-title {
    margin-top: 5rem;
    text-align: center;
    box-shadow: rgb(0,0,206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px, rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px, rgba(30, 35, 87, 0.05) -18px 18px;
    top: -2rem;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border-radius: 25px !important;
    display: flex;
    position: relative;
    height: 3rem;
    background-image: linear-gradient(to right bottom, rgb(0, 0, 0), rgb(0,0,206), rgb(0, 0, 0));
    color: white;
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }