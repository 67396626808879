.product-list-container {
  margin: 3rem;
  .product-list-title {
    margin-top: 5rem;
    text-align: center;
    box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
      rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
      rgba(30, 35, 87, 0.05) -18px 18px;
    top: -2rem;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border-radius: 25px !important;
    display: flex;
    position: relative;
    height: 3rem;
    background-image: linear-gradient(
      to right bottom,
      rgb(0, 0, 0),
      rgb(0, 0, 206),
      rgb(0, 0, 0)
    );
    color: white;
    width: 25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .product-list {
    div {
      margin: 1rem;
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) {
  .product-list-container {
    .product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 0 5rem;
      // height: 40rem;
      div {
        flex: 0 0 300px;
      }
    }
  }
}

.sub-header {
  display: flex;
  justify-content: center;
  height: 1rem;
}

.category-btns-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  gap: 1rem;
}
