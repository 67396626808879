code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*** Top Navbar Styling ***/

.icon-logos {
  font-size: 1rem;
  margin-left: 2rem;
  margin-right: 0.2rem;
}

.social-icon {
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  margin-bottom: 0.3rem;
}

.nav-header {
  color: white;
  margin-top: 0.3rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -1rem;
}

.dropdown-menu {
  background-color: black !important;
}

.dropdown-item:hover {
  background-color: #e86100 !important;
}

.cart-nav-styling:hover {
  background-color: #e86100;
  /* width: 2rem; */
  padding-right: 2rem;
}

.cart-nav-styling {
  cursor: pointer !important;
}

.cart-count {
  background-color: white;
  border-radius: 25px;
  padding: 0.2rem;
  margin: 0.2rem;
}

.login-btn {
  text-decoration: none;
  color: white !important;
  cursor: pointer;
}

.login-btn:hover {
  color: #ff6a00 !important;
}

.cart-btn {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.cart-btn :nth-child(2) {
  color: black;
}

.cart-btn:hover :nth-child(2) {
  color: #ff6a00;
}

.cart-btn:hover {
  color: #ff6a00 !important;
}

.top-nav-text:hover {
  color: #ff6a00 !important;
}

/*** Bottom Navbar Styling ***/

.bottom-nav {
  background-image: linear-gradient(
    115deg,
    #000 20%,
    rgb(0, 0, 148) 60%,
    rgb(0, 0, 206) 100%
  );
  height: 5rem;
  font-size: 1.5rem;
}

.bottom-nav-buttons {
  color: white !important;
  border-color: #e86100 !important;
  margin-right: 1.25rem;
}

.bottom-nav-buttons:hover {
  background-color: #e86100 !important;
}

#basic-nav-dropdown {
  color: white;
}

#search-bar {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-dropdown-text {
  color: white !important;
}

.shop-dropdown-divider {
  color: rgb(249, 253, 0) !important;
  height: 0.25rem !important;
}

/*** Top Main ***/

#tempImg {
  height: 40rem;
}

.customImg {
  height: 10rem;
  background-image: linear-gradient(
    to right top,
    #000 1%,
    rgb(214, 86, 0) 35%,
    #e86100 100%
  );
  content: "";
  display: block;
  position: relative;
  top: -4rem;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
  z-index: 2;
}

.top-headline {
  display: flex;
  position: relative;
  z-index: 3;
  height: 3rem;
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 0, 0),
    rgb(0, 0, 206),
    rgb(0, 0, 0)
  );
  color: white;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  top: -15rem;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 25px !important;
  box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
    rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
    rgba(30, 35, 87, 0.05) -18px 18px;
}

/*** Individual Card Rendering ***/

.card {
  background-color: rgb(175, 175, 175) !important;
  display: flex !important;
  justify-content: center !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  height: 36rem;
  width: 15rem !important;
  padding: 1rem;
  border-radius: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-info1 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 1rem;
  font-size: 23px !important;
}

.card-info-2 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 1rem;
  font-size: 15px !important;
}

.card-info-3 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 2rem;
}

.card-info-4 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 2rem;
}

.carousel-container {
  position: relative;
  z-index: 3;
  top: -12rem;
}

.card-image-container {
  background-color: white !important;
  padding: 0.75rem;
  margin: -1.1rem;
  border-radius: 25px;
}

.card-image-container:hover {
  cursor: pointer;
}

.card-info-container {
  background-color: rgb(161, 161, 161);
  position: relative;
  display: flex;
  flex-direction: column;
  /* border-radius: 25px; */
  top: 1.1rem;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.card-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
  bottom: 10% !important;
  position: relative;
  /* height: 100rem; */
}

.single-card-img {
  height: 23rem !important;
}

/*** New Releases ***/

/* .new-release-container {
  height: 20rem;
} */

.customImgTwo {
  display: flex;
  justify-content: space-evenly;
  /* background-color: rgb(175, 175, 175); */
  background-image: linear-gradient(
    to right bottom,
    rgb(175, 175, 175) 0px,
    rgba(155, 155, 155, 0.8) 50%,
    rgb(117, 117, 117)
  );
  top: -7rem !important;
  position: relative;
  height: 50rem;
}

.mid-headline {
  display: flex;
  position: relative;
  z-index: 3;
  height: 3rem;
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 0, 0),
    rgb(0, 0, 206),
    rgb(0, 0, 0)
  );
  color: white;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  top: -54rem;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 25px !important;
  box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
    rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
    rgba(30, 35, 87, 0.05) -18px 18px;
}

.box-img {
  width: 60% !important;
}

.box-img2 {
  width: 40% !important;
}

.product-container {
  width: 35rem !important;
  background-image: linear-gradient(
    to right top,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  justify-content: center !important;
  border: white solid 0.5rem !important;
  align-items: center !important;
  z-index: 1;
}

.product-container2 {
  width: 50rem !important;
  background-image: linear-gradient(
    to left bottom,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-content: center !important;
  /* justify-content: center !important; */
  border: white solid 0.5rem !important;
  align-items: center !important;
}

.card-content {
  justify-content: right !important;
  align-items: right !important;
  display: flex !important;
  text-align: right;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  text-align: center !important;
}

/*** Bottom Main ***/

.product-container3 {
  width: 50rem !important;
  background-image: linear-gradient(
    to left top,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-content: center !important;
  justify-content: center !important;
  border: white solid 0.5rem !important;
  align-items: center !important;
  z-index: 1;
}

.product-container4 {
  width: 35rem !important;
  background-image: linear-gradient(
    to right top,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  justify-content: center !important;
  border: white solid 0.5rem !important;
  align-items: center !important;
  z-index: 1;
}

@media only screen and (max-width: 1370px) {
  .product-container {
    width: 30rem !important;
  }

  .product-container2 {
    width: 45rem !important;
  }

  .product-container3 {
    width: 45rem !important;
  }

  .product-container4 {
    width: 30rem !important;
  }
}

.customImgThree {
  height: 10rem;
  /* background-color: rgb(18, 28, 71); */
  content: "";
  display: block;
  position: relative;
  top: -5rem;
  left: 0;
  right: 0;
  transform: skewY(3deg);
  z-index: 0;
}

@media only screen and (max-width: 1230px) {
  .product-container {
    display: none !important;
  }

  .product-container2 {
    width: 45rem !important;
  }

  .product-container3 {
    display: none !important;
  }

  .product-container4 {
    width: 45rem !important;
    display: flex;
    justify-content: center;
  }

  .customImgTwo,
  .customImgFour {
    display: flex !important;
    justify-content: center !important;
  }
}

.bottom-headline {
  display: flex;
  position: relative;
  z-index: 3;
  height: 3rem;
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 0, 0),
    rgb(0, 0, 206),
    rgb(0, 0, 0)
  );
  color: white;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  top: -18rem;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 25px !important;
  box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
    rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
    rgba(30, 35, 87, 0.05) -18px 18px;
}

.customImgFour {
  display: flex;
  justify-content: space-evenly;
  /* background-color: rgb(175, 175, 175); */
  /* background-image: linear-gradient(to right bottom, rgb(175, 175, 175) 0px, rgba(222, 224, 227, 0.8) 50%, rgb(117, 117, 117)); */
  top: -25rem !important;
  position: relative;
  height: 50rem;
}

.customImgFive {
  height: 10rem;
  background-image: linear-gradient(
    to left top,
    #000 5%,
    rgb(0, 0, 148) 85%,
    rgb(0, 0, 206) 100%
  );
  content: "";
  display: block;
  position: relative;
  top: -23rem;
  left: 0;
  right: 0;
  transform: skewY(3deg);
  z-index: 0;
}

.banner-container {
  /* height: -50rem; */
}

.banner-image {
  position: relative;
  top: 5rem;
  width: 100%;
}

.bottom-main-container {
  height: 50rem;
}

/*** Footer ***/

.footer {
  width: 50%;
  margin: 4rem auto;
  text-align: center;
  position: relative;
}

.footer-logo {
  width: 50%;
  margin: 4rem auto;
  text-align: center;
  position: relative;
}

.footer-logo {
  width: 100% !important;
  position: relative;
  bottom: -5rem;
}
