.featured-product {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem;
    margin-left: 1rem 0;
    width: 10rem;
    height: 40em;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    align-self: center;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: .5s;
  }

  .featured-product:hover {
    transform: scale(1.05);
    box-shadow: rgb(216, 86, 0) 0px 5px 15px;
  }

  .featured-image {
      width: 15rem !important;
      height: 23rem !important;
  }

  .featured-image:hover {
    opacity: .5;
  }

  .card-description {
      margin-top: 2rem;
      margin-bottom: -.01rem;
  }

  .card-info {
      display: flex;
      justify-content: center;
      font-size: 1.75rem !important;
  }

  .card-info2 {
    display: flex;
    justify-content: center;
    font-size: medium;
    margin-top: 1rem;
    margin-bottom: -2rem;
  }

  .card-info3 {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: -2rem;
  }

  .btn-style {
    display: flex;
    justify-content: center;
    margin-top: 2rem !important;
  }
