.box-hoops {
    width: 20rem;
}

.release-date-heading {
    color: white;
    border-radius: 25px;
    box-shadow: rgb(0,0,206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px, rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px, rgba(30, 35, 87, 0.05) -18px 18px;
    background-image: linear-gradient(to right bottom, rgb(0, 0, 0), rgb(0,0,206), rgb(0, 0, 0));
    padding: 4px;
    margin-bottom: 1rem;
}

.details-content-releases {
    display: flex;
    justify-content: center;
    color: black;
}

.release-header {
    font-size: 3.25rem;
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
    // left: 4rem;
    position: relative;
    font-family: 'Anton', sans-serif;
    font-family: 'Permanent Marker', cursive;
}

.card-container2 {
    position: relative;
    left: -4rem;
}

.table {
    width: 30rem;
    border: 1px solid black;
    border-radius: 25rem;
}

.table-headings {
    font-size: 1.3rem;
}

tr:nth-child(even) {background-color: #f06400;}

th {
    background-color: rgb(0,0,206);
    color: white;
}
