@import url("https://fonts.googleapis.com/css2?family=Anton&family=Permanent+Marker&display=swap");

.top-nav-container {
  font-family: "Anton", sans-serif;
  font-family: "Permanent Marker", cursive;
  height: 3.3rem;
  background-color: black;
}

.logo {
  width: 10rem;
  position: relative;
  top: -1.5rem;
  margin-left: 2rem;
}

.logo:hover {
  transition: all 1s;
  transform: rotate(360deg);
}

.logoHeader {
  color: white;
  margin-left: 0.5rem;
  font-family: "Anton", sans-serif;
  font-family: "Permanent Marker", cursive;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.burger-menu {
  color: white;
  font-size: 4rem;
  position: fixed;
  right: 3rem;
  top: 3.75rem;
  transition: 1s;
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .burger-menu {
    display: none;
  }
}
