.inputs {
  margin: 0.25rem;
}

.reister-btn-container {
  margin: 0.25rem;
}

.register-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-card-container {
  height: 22rem !important;
  width: 25% !important;
  padding: 20px !important;
}
