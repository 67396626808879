.total-container {
        border: 1px solid rgba(0, 0, 0, 0.125);
        padding: 3rem;
        // margin-left: 5rem 0;
        width: 15rem;
        height: 100%;
        display: flex;
        position: relative;
        top: 2rem;
        left: 7%;
        flex-direction: column;
        // align-items: center;
        // align-content: center;
        // align-self: flex-start;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-is-white {
    margin-top: .5rem;
}

.total {
    margin-top: 2rem;
    margin-bottom: 2rem;
}