.inputs-login {
  margin: 0.25rem;
}

.login-btn-container {
  margin: 0.25rem;
}

.login-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}
