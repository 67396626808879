@import url('https://fonts.googleapis.com/css2?family=Anton&family=Permanent+Marker&display=swap');

.info-box {
    text-align: center;
    border-radius: 25px;
    padding: 1rem;
    color: #f06400;
    position: relative;
    top: 4rem;
}

.info-container {
    display: flex;
    justify-content: center;
    position: relative;
    top: -5rem !important;
}

.break-header {
    font-size: 3.25rem;
    display: flex;
    text-align: right;
    justify-content: center;
    font-family: 'Anton', sans-serif;
    font-family: 'Permanent Marker', cursive;
}

.details-content {
    display: flex;
    text-align: left;
    color: white;
}


.breaks-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.background-color-break-page {
    background-image: linear-gradient(115deg,#000 15%,rgb(175, 175, 175) 50%,rgba(155, 155, 155, 0.8) 100%);
    display: grid;
}
