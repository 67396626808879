@import url(https://fonts.googleapis.com/css2?family=Anton&family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*** Top Navbar Styling ***/

.icon-logos {
  font-size: 1rem;
  margin-left: 2rem;
  margin-right: 0.2rem;
}

.social-icon {
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  margin-bottom: 0.3rem;
}

.nav-header {
  color: white;
  margin-top: 0.3rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -1rem;
}

.dropdown-menu {
  background-color: black !important;
}

.dropdown-item:hover {
  background-color: #e86100 !important;
}

.cart-nav-styling:hover {
  background-color: #e86100;
  /* width: 2rem; */
  padding-right: 2rem;
}

.cart-nav-styling {
  cursor: pointer !important;
}

.cart-count {
  background-color: white;
  border-radius: 25px;
  padding: 0.2rem;
  margin: 0.2rem;
}

.login-btn {
  text-decoration: none;
  color: white !important;
  cursor: pointer;
}

.login-btn:hover {
  color: #ff6a00 !important;
}

.cart-btn {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.cart-btn :nth-child(2) {
  color: black;
}

.cart-btn:hover :nth-child(2) {
  color: #ff6a00;
}

.cart-btn:hover {
  color: #ff6a00 !important;
}

.top-nav-text:hover {
  color: #ff6a00 !important;
}

/*** Bottom Navbar Styling ***/

.bottom-nav {
  background-image: linear-gradient(
    115deg,
    #000 20%,
    rgb(0, 0, 148) 60%,
    rgb(0, 0, 206) 100%
  );
  height: 5rem;
  font-size: 1.5rem;
}

.bottom-nav-buttons {
  color: white !important;
  border-color: #e86100 !important;
  margin-right: 1.25rem;
}

.bottom-nav-buttons:hover {
  background-color: #e86100 !important;
}

#basic-nav-dropdown {
  color: white;
}

#search-bar {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-dropdown-text {
  color: white !important;
}

.shop-dropdown-divider {
  color: rgb(249, 253, 0) !important;
  height: 0.25rem !important;
}

/*** Top Main ***/

#tempImg {
  height: 40rem;
}

.customImg {
  height: 10rem;
  background-image: linear-gradient(
    to right top,
    #000 1%,
    rgb(214, 86, 0) 35%,
    #e86100 100%
  );
  content: "";
  display: block;
  position: relative;
  top: -4rem;
  left: 0;
  right: 0;
  -webkit-transform: skewY(-3deg);
          transform: skewY(-3deg);
  z-index: 2;
}

.top-headline {
  display: flex;
  position: relative;
  z-index: 3;
  height: 3rem;
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 0, 0),
    rgb(0, 0, 206),
    rgb(0, 0, 0)
  );
  color: white;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  top: -15rem;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 25px !important;
  box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
    rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
    rgba(30, 35, 87, 0.05) -18px 18px;
}

/*** Individual Card Rendering ***/

.card {
  background-color: rgb(175, 175, 175) !important;
  display: flex !important;
  justify-content: center !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  height: 36rem;
  width: 15rem !important;
  padding: 1rem;
  border-radius: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-info1 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 1rem;
  font-size: 23px !important;
}

.card-info-2 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 1rem;
  font-size: 15px !important;
}

.card-info-3 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 2rem;
}

.card-info-4 {
  display: flex;
  justify-content: center;
  position: relative;
  top: 2rem;
}

.carousel-container {
  position: relative;
  z-index: 3;
  top: -12rem;
}

.card-image-container {
  background-color: white !important;
  padding: 0.75rem;
  margin: -1.1rem;
  border-radius: 25px;
}

.card-image-container:hover {
  cursor: pointer;
}

.card-info-container {
  background-color: rgb(161, 161, 161);
  position: relative;
  display: flex;
  flex-direction: column;
  /* border-radius: 25px; */
  top: 1.1rem;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.card-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
  bottom: 10% !important;
  position: relative;
  /* height: 100rem; */
}

.single-card-img {
  height: 23rem !important;
}

/*** New Releases ***/

/* .new-release-container {
  height: 20rem;
} */

.customImgTwo {
  display: flex;
  justify-content: space-evenly;
  /* background-color: rgb(175, 175, 175); */
  background-image: linear-gradient(
    to right bottom,
    rgb(175, 175, 175) 0px,
    rgba(155, 155, 155, 0.8) 50%,
    rgb(117, 117, 117)
  );
  top: -7rem !important;
  position: relative;
  height: 50rem;
}

.mid-headline {
  display: flex;
  position: relative;
  z-index: 3;
  height: 3rem;
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 0, 0),
    rgb(0, 0, 206),
    rgb(0, 0, 0)
  );
  color: white;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  top: -54rem;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 25px !important;
  box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
    rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
    rgba(30, 35, 87, 0.05) -18px 18px;
}

.box-img {
  width: 60% !important;
}

.box-img2 {
  width: 40% !important;
}

.product-container {
  width: 35rem !important;
  background-image: linear-gradient(
    to right top,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  justify-content: center !important;
  border: white solid 0.5rem !important;
  align-items: center !important;
  z-index: 1;
}

.product-container2 {
  width: 50rem !important;
  background-image: linear-gradient(
    to left bottom,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-content: center !important;
  /* justify-content: center !important; */
  border: white solid 0.5rem !important;
  align-items: center !important;
}

.card-content {
  justify-content: right !important;
  align-items: right !important;
  display: flex !important;
  text-align: right;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  text-align: center !important;
}

/*** Bottom Main ***/

.product-container3 {
  width: 50rem !important;
  background-image: linear-gradient(
    to left top,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-content: center !important;
  justify-content: center !important;
  border: white solid 0.5rem !important;
  align-items: center !important;
  z-index: 1;
}

.product-container4 {
  width: 35rem !important;
  background-image: linear-gradient(
    to right top,
    rgb(0, 0, 71) 1%,
    rgb(214, 86, 0, 0.9) 35%,
    #e86100 100%
  );
  top: 10rem;
  display: flex !important;
  flex-direction: row !important;
  align-content: center !important;
  justify-content: center !important;
  border: white solid 0.5rem !important;
  align-items: center !important;
  z-index: 1;
}

@media only screen and (max-width: 1370px) {
  .product-container {
    width: 30rem !important;
  }

  .product-container2 {
    width: 45rem !important;
  }

  .product-container3 {
    width: 45rem !important;
  }

  .product-container4 {
    width: 30rem !important;
  }
}

.customImgThree {
  height: 10rem;
  /* background-color: rgb(18, 28, 71); */
  content: "";
  display: block;
  position: relative;
  top: -5rem;
  left: 0;
  right: 0;
  -webkit-transform: skewY(3deg);
          transform: skewY(3deg);
  z-index: 0;
}

@media only screen and (max-width: 1230px) {
  .product-container {
    display: none !important;
  }

  .product-container2 {
    width: 45rem !important;
  }

  .product-container3 {
    display: none !important;
  }

  .product-container4 {
    width: 45rem !important;
    display: flex;
    justify-content: center;
  }

  .customImgTwo,
  .customImgFour {
    display: flex !important;
    justify-content: center !important;
  }
}

.bottom-headline {
  display: flex;
  position: relative;
  z-index: 3;
  height: 3rem;
  background-image: linear-gradient(
    to right bottom,
    rgb(0, 0, 0),
    rgb(0, 0, 206),
    rgb(0, 0, 0)
  );
  color: white;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  top: -18rem;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 25px !important;
  box-shadow: rgb(0, 0, 206) -3px 3px, rgba(5, 9, 65, 0.3) -5px 5px,
    rgba(13, 20, 82, 0.2) -10px 10px, rgba(24, 25, 92, 0.1) -14px 14px,
    rgba(30, 35, 87, 0.05) -18px 18px;
}

.customImgFour {
  display: flex;
  justify-content: space-evenly;
  /* background-color: rgb(175, 175, 175); */
  /* background-image: linear-gradient(to right bottom, rgb(175, 175, 175) 0px, rgba(222, 224, 227, 0.8) 50%, rgb(117, 117, 117)); */
  top: -25rem !important;
  position: relative;
  height: 50rem;
}

.customImgFive {
  height: 10rem;
  background-image: linear-gradient(
    to left top,
    #000 5%,
    rgb(0, 0, 148) 85%,
    rgb(0, 0, 206) 100%
  );
  content: "";
  display: block;
  position: relative;
  top: -23rem;
  left: 0;
  right: 0;
  -webkit-transform: skewY(3deg);
          transform: skewY(3deg);
  z-index: 0;
}

.banner-container {
  /* height: -50rem; */
}

.banner-image {
  position: relative;
  top: 5rem;
  width: 100%;
}

.bottom-main-container {
  height: 50rem;
}

/*** Footer ***/

.footer {
  width: 50%;
  margin: 4rem auto;
  text-align: center;
  position: relative;
}

.footer-logo {
  width: 50%;
  margin: 4rem auto;
  text-align: center;
  position: relative;
}

.footer-logo {
  width: 100% !important;
  position: relative;
  bottom: -5rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.top-nav-container{font-family:"Anton",sans-serif;font-family:"Permanent Marker",cursive;height:3.3rem;background-color:#000}.logo{width:10rem;position:relative;top:-1.5rem;margin-left:2rem}.logo:hover{transition:all 1s;-webkit-transform:rotate(360deg);transform:rotate(360deg)}.logoHeader{color:#fff;margin-left:.5rem;font-family:"Anton",sans-serif;font-family:"Permanent Marker",cursive}.logoContainer{display:flex;align-items:center}.burger-menu{color:#fff;font-size:4rem;position:fixed;right:3rem;top:3.75rem;transition:1s;cursor:pointer}@media screen and (min-width: 1025px){.burger-menu{display:none}}
.single-card-img:hover{opacity:.5}
.featured-product{border:1px solid rgba(0,0,0,.125);padding:1rem;margin-left:1rem 0;width:10rem;height:40em;display:flex;flex-direction:column;align-items:center;align-content:center;align-self:center;border-radius:25px;box-shadow:rgba(0,0,0,.35) 0px 5px 15px;transition:.5s}.featured-product:hover{-webkit-transform:scale(1.05);transform:scale(1.05);box-shadow:#d85600 0px 5px 15px}.featured-image{width:15rem !important;height:23rem !important}.featured-image:hover{opacity:.5}.card-description{margin-top:2rem;margin-bottom:-0.01rem}.card-info{display:flex;justify-content:center;font-size:1.75rem !important}.card-info2{display:flex;justify-content:center;font-size:medium;margin-top:1rem;margin-bottom:-2rem}.card-info3{display:flex;justify-content:center;margin-top:3rem;margin-bottom:-2rem}.btn-style{display:flex;justify-content:center;margin-top:2rem !important}
.product-list-container{margin:3rem}.product-list-container .product-list-title{margin-top:5rem;text-align:center;box-shadow:#0000ce -3px 3px,rgba(5,9,65,.3) -5px 5px,rgba(13,20,82,.2) -10px 10px,rgba(24,25,92,.1) -14px 14px,rgba(30,35,87,.05) -18px 18px;top:-2rem;justify-content:center;align-items:center;font-size:40px;border-radius:25px !important;display:flex;position:relative;height:3rem;background-image:linear-gradient(to right bottom, rgb(0, 0, 0), rgb(0, 0, 206), rgb(0, 0, 0));color:#fff;width:25rem;margin-left:auto;margin-right:auto}.product-list-container .product-list div{margin:1rem;cursor:pointer}@media(min-width: 1024px){.product-list-container .product-list{display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0 5rem}.product-list-container .product-list div{flex:0 0 300px}}.sub-header{display:flex;justify-content:center;height:1rem}.category-btns-container{display:flex;justify-content:center;margin-bottom:1.5rem;margin-top:2rem;grid-gap:1rem;gap:1rem}
.single-product-container{margin:2rem 0;margin-bottom:3rem;padding:2rem}@media(min-width: 1024px){.single-product-container{display:flex;justify-content:center;align-items:center}.single-product-container .product-image,.single-product-container .product-details{flex:0 0 400px}}.single-product-container .product-image{margin:2rem}.single-product-container .product-details .name-price{margin:1rem 0}.single-product-container .product-details .add-to-cart-btns{margin:2rem 0}.single-product-container .product-details .product-description p{line-height:1.5}.checkout-btn{margin-left:.5rem}.product-details{text-align:center}
.total-container{border:1px solid rgba(0,0,0,.125);padding:3rem;width:15rem;height:100%;display:flex;position:relative;top:2rem;left:7%;flex-direction:column;border-radius:25px;box-shadow:rgba(0,0,0,.35) 0px 5px 15px}.button-is-white{margin-top:.5rem}.total{margin-top:2rem;margin-bottom:2rem}
h1,.empty-cart{text-align:center}.cart-item{text-align:center;border:1px solid rgba(0,0,0,.125);border-radius:25px;box-shadow:rgba(0,0,0,.35) 0px 5px 15px;margin:2rem;padding:1rem}.cart-item .item-image img{width:50px}.cart-item .name-price h4{margin:0}.cart-item .name-price p{font-size:1rem;font-weight:normal}.cart-item .btns-container button{border:none;padding:.5rem 1rem;cursor:pointer;font-size:10px;margin-right:5px}.cart-item .btns-container .btn-increase{color:#fff;background-color:#1a1a1a}.cart-item .btns-container .btn-decrease,.cart-item .btns-container .btn-trash{color:#fff;background-color:#d9534f}.cart-item .total-container{margin:2rem;text-align:center}@media(min-width: 1024px){.cart-page{display:flex;justify-content:center}.cart-page .total-container{text-align:center}.cart-item{display:flex;justify-content:space-between}.cart-item div{margin:1rem}.cart-item .name-price{width:200px}}.product-list-title{margin-top:5rem;text-align:center;box-shadow:#0000ce -3px 3px,rgba(5,9,65,.3) -5px 5px,rgba(13,20,82,.2) -10px 10px,rgba(24,25,92,.1) -14px 14px,rgba(30,35,87,.05) -18px 18px;top:-2rem;justify-content:center;align-items:center;font-size:40px;border-radius:25px !important;display:flex;position:relative;height:3rem;background-image:linear-gradient(to right bottom, rgb(0, 0, 0), rgb(0, 0, 206), rgb(0, 0, 0));color:#fff;width:20rem;margin-left:auto;margin-right:auto}
.success-header{position:relative;margin-top:5rem}.link{display:flex;justify-content:center}
.info-box{text-align:center;border-radius:25px;padding:1rem;color:#f06400;position:relative;top:4rem}.info-container{display:flex;justify-content:center;position:relative;top:-5rem !important}.break-header{font-size:3.25rem;display:flex;text-align:right;justify-content:center;font-family:"Anton",sans-serif;font-family:"Permanent Marker",cursive}.details-content{display:flex;text-align:left;color:#fff}.breaks-container{position:relative;display:flex;justify-content:center}.background-color-break-page{background-image:linear-gradient(115deg, #000 15%, rgb(175, 175, 175) 50%, rgba(155, 155, 155, 0.8) 100%);display:grid}
.box-hoops{width:20rem}.release-date-heading{color:#fff;border-radius:25px;box-shadow:#0000ce -3px 3px,rgba(5,9,65,.3) -5px 5px,rgba(13,20,82,.2) -10px 10px,rgba(24,25,92,.1) -14px 14px,rgba(30,35,87,.05) -18px 18px;background-image:linear-gradient(to right bottom, rgb(0, 0, 0), rgb(0, 0, 206), rgb(0, 0, 0));padding:4px;margin-bottom:1rem}.details-content-releases{display:flex;justify-content:center;color:#000}.release-header{font-size:3.25rem;display:flex;margin-bottom:2rem;justify-content:center;position:relative;font-family:"Anton",sans-serif;font-family:"Permanent Marker",cursive}.card-container2{position:relative;left:-4rem}.table{width:30rem;border:1px solid #000;border-radius:25rem}.table-headings{font-size:1.3rem}tr:nth-child(even){background-color:#f06400}th{background-color:#0000ce;color:#fff}
.me-image{width:17rem;border-radius:25px}.about-container{background-size:cover;display:flex;align-items:center;justify-content:center}.register-card{height:19rem !important;width:25% !important;padding:20px !important}
.inputs-login{margin:.25rem}.login-btn-container{margin:.25rem}.login-link-container{display:flex;flex-direction:column;align-items:center}.form-container-login{display:flex;flex-direction:column;align-items:center}
.inputs{margin:.25rem}.reister-btn-container{margin:.25rem}.register-link-container{display:flex;flex-direction:column;align-items:center}.form-container{display:flex;flex-direction:column;align-items:center}.register-card-container{height:22rem !important;width:25% !important;padding:20px !important}
body{font-family:"Fira Sans",sans-serif;background-image:linear-gradient(to right bottom, rgb(248, 248, 248) 0px, rgba(222, 224, 227, 0.8) 50%, rgb(248, 248, 248))}.featured-collection{margin-top:6rem}.featured-collection .featured-section-title{font-size:2rem}.featured-collection .featured-image{cursor:pointer}@media(min-width: 1024px){.featured-collection .products{display:flex;justify-content:space-between;padding:5px}.featured-collection .products .featured-product{margin:10px}}
