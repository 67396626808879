@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&display=swap');
body {
  font-family: 'Fira Sans', sans-serif;
  background-image: linear-gradient(to right bottom, rgb(248, 248, 248) 0px, rgba(222, 224, 227, 0.8) 50%, rgb(248, 248, 248))
}

// Featured products section
.featured-collection {
    margin-top: 6rem;
  
    .featured-section-title {
      font-size: 2rem;
    }
  
    .featured-image {
      cursor: pointer;
    }
  
    @media(min-width: 1024px) {
      .products {
        display: flex;
        justify-content: space-between;
        padding: 5px;
  
        .featured-product {
          margin: 10px;
        }
      }
    }
  }